import React from "react";
import { Flex, Box, Text, styled } from "@100mslive/react-ui";

import { HubspotChatButton } from "./HubspotChatButton";

import img404 from "./404_prono.png";

const Link = styled("a", {
  color: "#a7986e",
  wordBreak: "break-word",
  textDecoration: "underline",
});

function ErrorPage({ error }) {
  const isLocalhost = false; // window.location.hostname === "localhost";

  return (
    <Flex
      align="center"
      justify="center"
      css={{
        size: "100%",
        color: "#1f3352",
        backgroundColor: "$bgPrimary",
      }}
    >
      <Box css={{ position: "relative", overflow: "hidden", r: "$3" }}>
        <Box
          css={{
            width: 500,
            height: 500,
            backgroundColor: "white",
            color: "#1f3352",
          }}
        />
        {isLocalhost ? (
          <Flex
            align="center"
            direction="column"
            css={{ position: "absolute", size: "100%", top: "33.33%", left: 0 }}
          >
            <Text variant="h3">Almost There!</Text>
            <Text
              variant="body1"
              css={{ margin: "1.75rem", textAlign: "center" }}
            >
              {
                "Hi there! thanks for trying us out, there is not much here yet. "
              }{" "}
              for next steps
            </Text>
          </Flex>
        ) : (
          <Flex
            align="center"
            justify="center"
            direction="column"
            css={{
              position: "absolute",
              size: "100%",
              top: "5%",
              left: 0,
              pl: "1rem",
              pr: "1rem",
              textAlign: "center",
              color: "#1f3352",
            }}
          >
            <Flex css={{ width: "50%" }}>
              <img src={img404} />
            </Flex>
            <Text variant="body1" css={{ mt: "1rem", color: "#1f3352" }}>
              Uuuups, no Prönös here!
            </Text>
            <Text variant="body1" css={{ mt: "1rem", color: "#1f3352" }}>
              Contact us on chat if you need help quickly.
            </Text>
            <Text variant="body1" css={{ mt: "1rem", color: "#1f3352" }}>
              Or send us feedback{" "}
              <Link href="mailto:info@brainbitesai.com?subject=Video app not available">
                here
              </Link>{" "}
              to help us figure out how on earth this happened
            </Text>
            <Text variant="body1" css={{ mt: "1rem", color: "#1f3352" }}>
              <Link href="https://app.brainbitesai.com">Go back to the platform</Link>
            </Text>
            <Text variant="h4" css={{ mt: "1.75rem" }}>
              {error}
            </Text>
          </Flex>
        )}
      </Box>
      <Flex css={{ position: "fixed", bottom: 16, right: 16 }}>
        <HubspotChatButton label="Chat" />
      </Flex>
    </Flex>
  );
}

ErrorPage.displayName = "ErrorPage";

export default ErrorPage;
